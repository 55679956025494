import React, { useMemo } from "react";
import { BeatLoader } from "react-spinners";
import {
  GridColumn,
  Segment,
  Icon,
} from "semantic-ui-react";

const heightMapping = {
  5: 90,
  6: 75,
  7: 65,
  8: 55,
  9: 55,
  10: 55,
};

const ChargeModule = ({
  module_number,
  doorStatusMap,
  setSelectedModuleNumber,
  selectedModuleNumber,
  module_count,
}) => {
  const targetDoors = Object.keys(doorStatusMap)
  const module_number_string = String(module_number)
  return (
    <>
      <GridColumn style={{ padding: "2px 8px 2px 8px" }} width="7">
        <Segment
          style={{
            backgroundColor:
              module_number === selectedModuleNumber ? "#444" : "#D0D0D0",
            height: heightMapping[module_count],
          }}
          onClick={() =>
            targetDoors.includes(module_number) &&
            setSelectedModuleNumber(module_number)
          }
        >
          {targetDoors.includes(module_number) && (
            <Icon
              name={doorStatusMap[module_number_string]?.toLowerCase().includes("open") ? "unlock" : "lock"}
              style={{
                color:
                  module_number === selectedModuleNumber ? "#D0D0D0" : "#444",
                marginTop: module_count <= 5 ? 16 : 8,
              }}
              size="big"
            />
          )}
        </Segment>
      </GridColumn>
      <GridColumn
        style={{ padding: 5, color: "#444" }}
        verticalAlign="middle"
        width="9"
      >
        {doorStatusMap[module_number_string]?.toLowerCase().includes("open")
          ? `Module ${module_number} open`
          : doorStatusMap[module_number_string]?.toLowerCase().includes("locked")
            ? `Module ${module_number} closed`
            : targetDoors.includes(module_number)
              ? <BeatLoader
                color={"white"}
                loading={doorStatusMap[module_number_string]?.toLowerCase().includes("open") ? false : true}
                height={10}
                width={30}
              />
              : null}
      </GridColumn>
    </>
  );
};

const StateOfCharge = ({ index, selectedModuleNumber, box_status }) => {
  const state_of_charge = useMemo(
    () =>
      box_status.boxes.find((box) => box.box_id === +selectedModuleNumber)
        .slots[index]?.state_of_charge,
    [box_status]
  );
  return (
    <div className="text-inside-battery-icon">
      {state_of_charge && `${state_of_charge}%`}
    </div>
  );
};

export { heightMapping, ChargeModule, StateOfCharge }